<template>
  <v-app id="app" class="app">
    <div
      v-if="_showLogo"
      class="d-flex justify-center align-center fill-width pt-10"
    >
      <v-img :src="require('@/assets/logo.png')" max-width="20rem" />
    </div>

    <v-main>
      <router-view />
    </v-main>

    <AlertBar ref="alertBar" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getAttendance } from "@/services/attendance";
import AlertBar from "./components/alertBar/AlertBar.vue";
import { showError } from "./utils";

export default {
  name: "App",

  components: {
    AlertBar,
  },

  data() {
    return {
      interval: null,
    };
  },

  created() {
    this.handleContinueAttendance();
    window.addEventListener("beforeunload", this.handleClearInterval);
  },

  beforeMount() {
    this.$root.$on("alert", this.handleAlert);
    this.$root.$on("attendance:finish", this.handleAttendanceFinish);
  },

  mounted() {
    this.handlePingSession();
  },

  computed: {
    ...mapState("socket", [
      "attendance",
      "inAttendance",
      "inQueue",
      "code",
      "isConnected",
    ]),

    _showLogo() {
      const routes = ["Attendance", "ChatBot"];

      return (
        this.$vuetify.breakpoint.mdAndUp || !routes.includes(this.$route.name)
      );
    },
  },

  watch: {
    inAttendance(value) {
      if (value) {
        this.$router
          .push({ path: `/attendance/${this.attendance._id}` })
          .catch(() => {});
      }
    },

    inQueue(value) {
      if (value) {
        this.$router.push({ path: "/queue" }).catch(() => {});
      }
    },
  },

  methods: {
    ...mapActions("socket", [
      "setAttendance",
      "cancelAttendance",
      "finishAttendance",
      "disconnectSocket",
      "setCode",
      "pingEmit",
      "setMessages",
      "addMessage",
    ]),

    async handleContinueAttendance() {
      if (this.attendance?._id) {
        await this.getAttendance();

        if (this.attendance?.status === "pending")
          return this.$router.push(`/chat-bot`).catch(() => {});

        if (this.attendance?.status === "waiting")
          return this.$router.push(`/queue`).catch(() => {});

        if (this.attendance?.status === "in-progress") {
          return this.$router
            .push(`/attendance/${this.attendance._id}`)
            .catch(() => {});
        }

        this.handleDestroy();
      }
    },

    async getAttendance() {
      try {
        const { data } = await getAttendance(this.attendance?._id, {
          code: this.code,
        });

        this.setAttendance(data);
        this.setMessages([]);

        data.messages.forEach((el) => {
          this.addMessage(el);
        });
      } catch (error) {
        console.log(error);
        // this.setAttendance(null);
      }
    },

    handleDestroy() {
      if (this.attendance) {
        this.handleAttendanceFinish();
      }

      this.handleClearInterval();

      this.$root.$off("alert", this.handleAlert);
      this.$root.$off("attendance:finish", this.handleAttendanceFinish);

      this.disconnectSocket();
    },

    handleAttendanceFinish() {
      try {
        const attendanceID = this.attendance?._id;
        if (!attendanceID) {
          return;
        }

        const payload = {
          attendanceID,
        };

        this.setMessages([]);

        if (["pending", "waiting"].includes(this.attendance.status)) {
          return this.cancelAttendance({
            data: payload,
            callback: (err, data) => {
              console.log("cancelAttendance:data", data);

              if (err) {
                console.log("cancelAttendance:error", err);
                throw new Error(err);
              }

              this.handleAlert({
                message: "Atendimento cancelado!",
                type: "warning",
              });
            },
          });
        }

        if (["in-progress"].includes(this.attendance.status)) {
          return this.finishAttendance({
            data: payload,
            callback: (err, data) => {
              console.log("finishAttendance:data", data);

              this.setCode(data.code);

              if (err) {
                console.log("finishAttendance:error", err);
                throw new Error(err);
              }

              this.handleAlert({
                message: "Atendimento finalizado!",
                type: "info",
              });
            },
          });
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.setAttendance(null);
      }
    },

    handlePingSession() {
      if (this.interval) this.handleClearInterval();

      this.pingEmit();

      this.interval = setInterval(() => {
        if (this.isConnected) return;

        console.log("ping");

        this.pingEmit();
      }, 10000);
    },

    handleAlert(event) {
      this.$refs.alertBar.handleAlert(event);
    },

    handleClearInterval() {
      clearInterval(this.interval);
    },

    showError,
  },
};
</script>

<style lang="scss" scoped>
.app {
  background: rgba(17, 17, 17, 0.089) !important;
}
</style>
