<template>
  <v-card
    class="rounded-xl"
    color="gray7"
    :height="height"
    :min-height="height"
    :max-height="height"
  >
    <div
      class="d-flex flex-column fill-height"
      @keypress.prevent.enter="submit()"
    >
      <v-app-bar
        class="pa-0 rounded-t-xl rounded-br-xl elevation-2"
        color="transparent"
        top
      >
        <img src="@/assets/mini_logo.png" alt="logo" class="imgLogo" />

        <div v-if="title" class="text-body-1 font-weight-bold">
          <span class="title-height">
            {{ title }}
          </span>
        </div>

        <div v-else-if="attendant && attendant.name" class="text-h6">
          {{ handleName(attendant.name) }}
        </div>

        <v-spacer />

        <slot name="menu">
          <v-menu
            v-if="!readMode"
            content-class="mt-2"
            min-width="12.5rem"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="custom-btn" small icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="handleFinalize()">
                <v-list-item-icon class="mr-2">
                  <v-icon small>mdi-close</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ finalizeLabel }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </slot>
      </v-app-bar>

      <!-- chat -->
      <div class="d-flex flex-column scroll-area fill-height my-2">
        <!-- messages -->
        <v-list class="pa-0 transparent" height="100%">
          <template v-for="(item, i) in chat.messages">
            <div
              v-show="item.message !== 'Sim, podemos seguir'"
              :key="i"
              class="my-6"
            >
              <!-- message -->
              <div
                class="d-flex align-end px-4"
                :class="{ 'flex-row-reverse text-right': item.me }"
              >
                <v-avatar
                  v-if="!item.me"
                  class="mr-2"
                  color="gray5"
                  size="2rem"
                >
                  <v-icon v-if="attendant && attendant.icon" dark small>
                    {{ attendant.icon }}
                  </v-icon>
                </v-avatar>

                <v-card
                  class="rounded-xl px-4 pt-2 pb-1"
                  :class="item.me ? 'rounded-br-0' : 'rounded-bl-0'"
                  flat
                >
                  <div class="d-flex flex-column">
                    <div class="text-body-1" :class="{ 'text-right': item.me }">
                      <a
                        v-if="isAttach(item.message) || isMeet(item.message)"
                        :href="item.message"
                        target="_blank"
                        class="d-flex flex-grow text-decoration-none"
                      >
                        <v-card color="gray7" class="py-1 px-4 rounded-lg" flat>
                          <div
                            v-if="isAttach(item.message)"
                            class="d-flex align-center"
                          >
                            <div
                              class="primary--text text-decoration-underline"
                            >
                              Visualizar anexo
                            </div>

                            <v-icon right small>mdi-attachment</v-icon>
                          </div>

                          <div
                            v-if="isMeet(item.message)"
                            class="d-flex align-center"
                          >
                            <div
                              class="primary--text text-decoration-underline"
                            >
                              Clique aqui para iniciar o atendimento por vídeo
                            </div>

                            <v-icon right small>mdi-video-box</v-icon>
                          </div>
                        </v-card>
                      </a>

                      <span v-else v-html="formattedMessage(item.message)" />
                    </div>

                    <!-- response options -->
                    <v-item-group
                      v-if="item.options && item.options.length"
                      class="my-2"
                    >
                      <v-item
                        v-for="(option, n) in item.options"
                        v-slot="{ active, toggle }"
                        :key="n"
                        @change="handleOption(option)"
                      >
                        <v-chip
                          class="ma-1 px-5"
                          active-class="orange white--text"
                          :input-value="active"
                          :disabled="i < chat.messages.length - 1 || readMode"
                          :outlined="!active"
                          @click="toggle"
                        >
                          <span class="text-body-1">{{
                            option ? option.label : ""
                          }}</span>
                        </v-chip>
                      </v-item>
                    </v-item-group>

                    <div v-if="item.options?.length === 0">
                      <div class="my-4">
                        <span class="text-body-2">
                          Não temos opções disponíveis no momento, tente
                          novamente mais tarde
                        </span>
                      </div>
                    </div>

                    <div class="d-flex text-body-2">
                      <v-spacer v-if="item.me" />

                      <div class="grey--text">
                        <span v-if="showAuthor && item.author">
                          {{ item.author.name }} -
                        </span>

                        {{ formatDate(item.createdAt, "HH:mm") }}
                      </div>

                      <v-spacer v-if="!item.me" />
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </template>
        </v-list>
      </div>

      <section v-if="!readMode">
        <v-divider class="white" />

        <v-app-bar class="pa-0" color="transparent" height="auto" bottom flat>
          <v-form
            v-model="valid"
            ref="form"
            class="fill-width"
            autocomplete="off"
          >
            <div class="d-flex align-center">
              <!-- masked input -->
              <v-text-field
                v-if="!!_mask"
                v-model="message"
                v-mask="_mask"
                id="maskedInput"
                placeholder="Digite aqui..."
                :readonly="_hasOptions || disabled"
                :disabled="(_hasOptions && !optionValue) || disabled"
                :rules="_rules"
                background-color="transparent"
                hide-details="auto"
                dense
                solo
                flat
              />

              <!-- naked input -->
              <v-text-field
                v-show="!_mask"
                v-model="message"
                id="nakedInput"
                placeholder="Digite aqui..."
                :readonly="_hasOptions || disabled"
                :disabled="(_hasOptions && !optionValue) || disabled"
                :rules="_rules"
                background-color="transparent"
                hide-details="auto"
                dense
                solo
                flat
                @keydown="handlePreventSpace"
              />

              <div class="ml-2">
                <v-btn
                  v-if="!hideAttach"
                  class="mr-1"
                  :disabled="disabled || !valid"
                  small
                  icon
                  @click="attachFile()"
                  color="orange"
                >
                  <v-icon>mdi-paperclip</v-icon>
                </v-btn>

                <v-btn
                  :disabled="disabled || !valid"
                  small
                  icon
                  @click="submit()"
                  color="orange"
                >
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-app-bar>
      </section>
    </div>

    <input
      ref="uploader"
      class="d-none"
      type="file"
      :maxlength="'25000000'"
      @change="onFileChanged($event)"
    />
  </v-card>
</template>

<script>
import { validation } from "@/utils/validators";
import { Howl, Howler } from "howler";
import { mask } from "vue-the-mask";
const moment = require("moment");
moment.locale("pt-BR");

export default {
  data() {
    return {
      valid: false,
      message: "",
      optionValue: null,
      rules: {
        required: [(v) => !!v || "Campo obrigatório"],
        email: [(v) => /.+@.+..+/.test(v) || "E-mail precisa ser válido"],
        cpf: [(v) => v.length === 14 || "CPF precisa ser válido"],
        phone: [(v) => v.length === 16 || "Telefone precisa ser válido"],
      },
    };
  },

  directives: {
    mask,
  },

  props: {
    chat: {
      type: Object,
      required: true,
    },

    attendant: {
      type: Object,
      required: true,
    },

    icon: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    height: {
      type: [String, Number],
      default: "100%",
    },

    finalizeLabel: {
      type: String,
      default: "Finalizar Atendimento",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    readMode: {
      type: Boolean,
      default: false,
    },

    hideAttach: {
      type: Boolean,
      default: false,
    },

    showAuthor: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollToEnd();
      this.playNotificationSound();
    });
  },

  watch: {
    ["chat.messages"]: {
      handler(val) {
        // scroll chat to end
        this.scrollToEnd();

        // clear validation
        if (this.$refs.form) this.$refs.form.resetValidation();

        // trigger notification sound
        if (val.length && !val[val.length - 1].me) this.playNotificationSound();

        // focus in input
        setTimeout(() => {
          if (this._mask) {
            const element = document.getElementById("maskedInput");
            if (element) element.focus();
          } else {
            const element = document.getElementById("nakedInput");
            if (element) element.focus();
          }
        }, 500);
      },
      deep: true,
    },

    message(val) {
      if (this._lastMessage?.rules?.includes("email")) {
        this.message = val.toLowerCase();
      }
    },
  },

  computed: {
    _hasOptions() {
      return !!this._lastMessage?.options?.length;
    },

    _mask() {
      if (!this._lastMessage) return false;

      return this._lastMessage.mask ? this._lastMessage.mask : false;
    },

    _rules() {
      const defaultRule = [(v) => validation(v) || "Informe um valor válido"];

      if (!this._lastMessage?.rules?.length) return defaultRule;

      return [
        ...this._lastMessage.rules
          .flatMap((key) => this.rules[key] ?? [])
          .filter((rule) => rule !== undefined),
        ...defaultRule,
      ];
    },

    _lastMessage() {
      const messages = this.chat.messages;
      return messages?.length ? messages[messages.length - 1] : false;
    },
  },

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;

      const payload = {
        message: this.message,
        value: this.optionValue,
        key: this._lastMessage?.key || null,
        next: this._lastMessage?.next || null,
      };
      this.$emit("send-message", payload);

      this.message = "";
      this.optionValue = null;
    },

    attachFile() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    onFileChanged(event) {
      const files = Array.from(event.target.files);
      this.$emit("attach-file", { file: files[0] });
    },

    handleOption(option) {
      this.message = option.label;
      this.optionValue = option.value;

      this.submit();
    },

    handlePreventSpace(event) {
      if (this._lastMessage?.rules?.includes("email") && event.keyCode === 32) {
        event.preventDefault();
      }
    },

    scrollToEnd() {
      setTimeout(() => {
        const element = document.querySelector(".scroll-area");
        if (element) {
          element.scrollTop = element.scrollHeight;
        }
      }, 100);
    },

    playNotificationSound() {
      if (this.readMode) return;
      Howler.autoUnlock = true;

      const notificationSound = new Howl({
        src: [
          "https://storage.googleapis.com/notification-song/message-pop-alert.mp3",
        ],
        volume: 1,
      });

      notificationSound.play();
    },

    formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
      return moment(date).format(format);
    },

    isAttach(message) {
      const baseURL = "https://storage.googleapis.com";
      return message.startsWith(baseURL);
    },

    isMeet(message) {
      const baseURL = "https://meet.google.com";
      return message.startsWith(baseURL);
    },

    formattedMessage(message) {
      const links = message.replace(
        /(?:https?|ftp):\/\/[\n\S]+/gi,
        (match) => `<a href="${match}" target="_blank">${match}</a>`
      );

      return links;
    },

    handleAutofill(value) {
      this.message = value;

      setTimeout(() => {
        this.submit();
      }, 100);
    },

    handleFinalize() {
      this.$emit("finalize");
    },

    handleName(value) {
      const names = value.trim().split(/\s+/);

      if (names.length === 1) return names[0];
      if (names.length >= 2) return `${names[0]} ${names[1]}`;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
