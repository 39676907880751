import store from "@/store";

export default async (to, from, next) => {
  //settings
  document.title = process.env.VUE_APP_DOCUMENT_TITLE;

  if (to.name !== from.name) window.scrollTo(0, 0);

  if (
    (to.name === "JoinAudience" ||
      to.name === "QueueAudience" ||
      to.name === "ChatAudience") &&
    store.getters.attendance
  ) {
    store.dispatch("resetAttendance");
  }

  next();
};
