import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import socket from "./socket";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "client-tj-am",
  storage: window.localStorage,
  reducer: (state) => ({
    socket: {
      attendance: state.socket.attendance,
      messages: state.socket.messages,
      code: state.socket.code,
    },
  }),
});

export default new Vuex.Store({
  state: {
    module: "",
    preSelection: {},
  },

  getters: {
    module: ({ module }) => module,
    preSelection: ({ preSelection }) => preSelection,
  },

  mutations: {
    SET_PRE_SELECTION(state, payload) {
      state.preSelection = payload;
    },
  },

  actions: {
    setPreSelection({ commit }, payload) {
      commit("SET_PRE_SELECTION", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {
    socket,
  },
});
